import { useIntl } from 'react-intl';

import { Input, Label, parseMarkdown, Text } from '@humanoids/ui';
import * as Styled from './InquiryDialogPersonalDetails.styled';

interface InquiryDialogPersonalDetailsInterface {
  onChange: (args?: unknown) => unknown;
}

export const InquiryDialogPersonalDetails = ({
  onChange,
}: InquiryDialogPersonalDetailsInterface) => {
  const intl = useIntl();

  return (
    <>
      <Text
        dangerouslySetInnerHTML={{
          __html: parseMarkdown(
            intl.formatMessage({
              id: 'inquiryPersonalDetailsIntro',
            })
          ),
        }}
        $align="center"
        $large
      />
      <Styled.Fieldset>
        <Label>
          {intl.formatMessage({
            id: 'firstName',
          })}
          <Input
            autoFocus
            data-1p-ignore
            minLength={2}
            name="firstName"
            onChange={onChange}
            required
            type="text"
          />
        </Label>
        <Label>
          {intl.formatMessage({
            id: 'lastName',
          })}
          <Input
            data-1p-ignore
            minLength={2}
            name="lastName"
            onChange={onChange}
            required
            type="text"
          />
        </Label>
        <Label>
          {intl.formatMessage({
            id: 'emailAddress',
          })}
          <Input
            data-1p-ignore
            name="emailAddress"
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
            onChange={onChange}
            required
            type="email"
          />
        </Label>
        <Label>
          {intl.formatMessage({
            id: 'phoneNumber',
          })}
          <Input
            minLength={10}
            name="phoneNumber"
            onChange={onChange}
            required
            type="text"
          />
        </Label>
      </Styled.Fieldset>
    </>
  );
};
