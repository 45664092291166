import Image from 'next/image';

import { Text, VariablesInterface, parseMarkdown } from '@humanoids/ui';

import { InquiryQuestionAnswerType } from '@humanoids/services/graphql/sdk';
import { imageUrlBuilder } from '@/services';
import { InquiryDialogAnswer } from './InquiryDialogAnswer';
import { InquiryDialogAnswers } from './InquiryDialogAnswers';

import * as Styled from './InquiryDialogQuestion.styled';

interface InquiryDialogQuestionInterface {
  answers: InquiryQuestionAnswerType[];
  formData: VariablesInterface;
  name: string;
  onProceed: () => void;
  question: string;
}

export const InquiryDialogQuestion = ({
  answers,
  formData,
  name,
  onProceed,
  question,
}: InquiryDialogQuestionInterface) => (
  <>
    <Text
      dangerouslySetInnerHTML={{
        __html: parseMarkdown(question, formData),
      }}
      $align="left"
      $large
    />
    <InquiryDialogAnswers>
      {answers &&
        answers.map(({ _id, answer, icon }) => (
          <InquiryDialogAnswer
            id={_id}
            key={_id}
            name={name}
            onSelect={onProceed}
            value={_id}
          >
            <Styled.Icon>
              {icon && (
                <Image
                  alt={icon.alt}
                  fill
                  src={imageUrlBuilder.image(icon).url()}
                />
              )}
            </Styled.Icon>
            <Text
              $align="center"
              as="span"
              dangerouslySetInnerHTML={{
                __html: parseMarkdown(answer),
              }}
              $small
            />
          </InquiryDialogAnswer>
        ))}
    </InquiryDialogAnswers>
  </>
);
