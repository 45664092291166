import { useIntl } from 'react-intl';
import { usePathname, useSearchParams, useRouter } from 'next/navigation';
import React, { PointerEvent, useEffect, useRef, useState } from 'react';

import { InquiryType } from '@humanoids/services/graphql/sdk';
import { Button } from '@humanoids/ui';
import {
  ContactType,
  addNoteToDeal,
  createContact,
  createDeal,
} from '@humanoids/services/hubspot';
import { ReactComponent as CloseIcon } from '@/images/cross.svg';

import { InquiryDialogComplete } from './InquiryDialogComplete';
import { InquiryDialogPersonalDetails } from './InquiryDialogPersonalDetails';
import { InquiryDialogProjectDetails } from './InquiryDialogProjectDetails';
import { InquiryDialogQuestion } from './InquiryDialogQuestion';

import * as Styled from './InquiryDialog.styled';
import { slice } from 'stylis';

type FormType = {
  [key: string]: string;
};

type StagesType =
  | 'questions'
  | 'personalDetails'
  | 'projectDetails'
  | 'complete';

interface InquiryDialogProps {
  inquiry: InquiryType;
}

export const InquiryDialog = ({ inquiry }: InquiryDialogProps) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [answers, setAnswers] = useState([]);
  const [dealId, setDealId] = useState<number>();
  const [form, setForm] = useState<FormType>({});
  const [formValidity, setFormValidity] = useState(false);
  const [stage, setStage] = useState<StagesType>('questions');
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();
  const showDialog = searchParams.get('inquiry');

  const intl = useIntl();

  const answerIds = answers.map((answer) => Object.values(answer)).flat();
  const remainingQuestions = inquiry.questions.filter(
    (question) =>
      !question.answers.some(({ _id }) => answerIds.includes(_id)) &&
      (!question.conditional || answerIds.includes(question.conditional?._id))
  );
  const currentQuestion = remainingQuestions.at(0);
  const progress =
    ((stage === 'questions'
      ? 1
      : stage === 'personalDetails'
      ? 2
      : stage === 'projectDetails'
      ? 3
      : 4) +
      answers.length) /
    (answers.length + remainingQuestions.length + 4);

  const handleClick = (event: MouseEvent) => {
    if (!(event as unknown as PointerEvent).pointerType) {
      return;
    }

    const dialogDimensions = dialogRef.current.getBoundingClientRect();
    if (
      event.clientX < dialogDimensions.left ||
      event.clientX > dialogDimensions.right ||
      event.clientY < dialogDimensions.top ||
      event.clientY > dialogDimensions.bottom
    ) {
      router.push(pathname, { scroll: false });
    }
  };

  const handleClose = () => {
    router.push(pathname, { scroll: false });

    if (stage === 'complete') {
      setAnswers([]);
      setForm({});
      setStage('questions');
    }
  };

  useEffect(() => {
    const currentRef = dialogRef.current;

    currentRef.addEventListener('click', handleClick);

    return () => {
      currentRef.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (showDialog === 'true' && !dialogRef.current?.open) {
      dialogRef.current?.showModal();
      // NOTE: Required to prevent the close button from getting focused on mobile
      (document.activeElement as HTMLElement).blur();
    } else if (dialogRef.current.open) {
      dialogRef.current.close();
    }
  }, [showDialog]);

  if (stage === 'questions' && !currentQuestion) {
    setStage('personalDetails');
  }

  const handleChange = () => {
    setFormValidity(formRef.current.checkValidity());
  };

  const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (stage === 'personalDetails' && answers.length) {
      setAnswers(answers.slice(0, -1));
      setStage('questions');
    }

    if (stage === 'questions' && answers.length) {
      setAnswers(answers.slice(0, -1));
      return;
    }
  };

  const handleProceed = async () => {
    const formData = Object.fromEntries(
      new FormData(formRef.current)
    ) as FormType;

    if (stage === 'personalDetails') {
      setForm({ ...form, ...formData });
      setStage('projectDetails');

      console.log('formData', formData);

      const contact = await createContact(formData as ContactType);

      console.log('contact', contact);

      const deal = await createDeal(contact.id);

      console.log('deal', deal);

      setDealId(deal.id);

      const mergedAnswers = answers.reduce(
        (acc, curr) => ({ ...acc, ...curr }),
        []
      );

      const note = Object.keys(mergedAnswers).reduce((acc, curr) => {
        const question = inquiry.questions.find(({ _id }) => _id === curr);
        const answer = inquiry.questions
          .flatMap(({ answers }) => answers)
          .find(({ _id }) => _id === mergedAnswers[curr]);

        return `${acc}${question?.question}<br />\n${answer?.answer}<br /><br />\n\n`;
      }, '');

      addNoteToDeal(deal.id, note);

      return;
    }

    if (stage === 'projectDetails') {
      setForm({ ...form, ...formData });

      const note =
        form['budget'] !== formData['budget']
          ? `Budget:<br />\n${formData['budget']}`
          : `Planning:<br />\n${formData['planning']}`;

      addNoteToDeal(dealId, note);

      return;
    }

    setAnswers([...answers, formData]);
  };

  return (
    <Styled.InquiryDialog ref={dialogRef} $open={showDialog === 'true'}>
      <Styled.CloseButton onClick={handleClose} quaternary>
        {intl.formatMessage({
          id: 'close',
        })}
        <CloseIcon />
      </Styled.CloseButton>
      <Styled.Form
        onSubmit={(event) => {
          event.preventDefault();
          handleProceed();
        }}
        ref={formRef}
      >
        {stage === 'questions' && currentQuestion && (
          <InquiryDialogQuestion
            answers={currentQuestion?.answers}
            formData={form}
            name={currentQuestion._id}
            onProceed={handleProceed}
            question={currentQuestion.question}
          />
        )}
        {stage === 'personalDetails' && (
          <InquiryDialogPersonalDetails onChange={handleChange} />
        )}
        {stage === 'projectDetails' && (
          <InquiryDialogProjectDetails
            budget={inquiry.budget}
            onFinalize={() => setStage('complete')}
            onProceed={handleProceed}
            planning={inquiry.planning}
            variables={form}
          />
        )}
        {stage === 'complete' && (
          <InquiryDialogComplete onClose={handleClose} variables={form} />
        )}
        {stage !== 'complete' && answers.length > 0 && (
          <Styled.Buttons>
            {stage !== 'projectDetails' && answers.length > 0 && (
              <Button quaternary onClick={handlePrevious}>
                {intl.formatMessage({
                  id: 'back',
                })}
              </Button>
            )}
            {stage === 'personalDetails' && (
              <Button disabled={!formValidity} tertiary={formValidity}>
                {intl.formatMessage({
                  id: 'continue',
                })}
              </Button>
            )}
          </Styled.Buttons>
        )}
        <Styled.ProgressBar $progress={progress} />
      </Styled.Form>
    </Styled.InquiryDialog>
  );
};
