import { ConfigurationType, PageType } from '@humanoids/services/graphql/sdk';

export const generatePath = (
  page: PageType,
  configuration: ConfigurationType[],
  path = []
): string[] => {
  const { articles, cases, homepage } = configuration.find(
    ({ i18n_lang }) => i18n_lang === page.i18n_lang
  );

  if (page.slug?.current === homepage.slug.current) {
    return [''];
  }

  if (['article', 'case'].includes(page?.type)) {
    return generatePath(
      page?.type === 'article' ? articles : cases,
      configuration,
      [page.slug?.current]
    );
  } else if (page?.parentPage) {
    return generatePath(page.parentPage, configuration, [
      page.slug?.current ?? '',
      ...path,
    ]);
  }

  return [page.slug?.current ?? '', ...path];
};
