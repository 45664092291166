import React, { ReactNode } from 'react';

import { Text } from '@humanoids/ui';

import * as Styled from './Button.styled';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  as?: React.ElementType;
  back?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  quaternary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  target?: string;
}

export const Button = React.forwardRef(
  (
    {
      back,
      children,
      disabled,
      quaternary,
      secondary,
      tertiary,
      ...props
    }: ButtonProps,
    ref
  ) => (
    <Styled.Button
      as="button"
      disabled={disabled}
      $hasChildren={children}
      $quaternary={quaternary}
      ref={ref}
      $secondary={secondary}
      $tertiary={tertiary}
      {...props}
    >
      {back && <Styled.ChevronLeft />}
      {children && (
        <Text as="span" $small>
          {children}
        </Text>
      )}
      {!back && <Styled.ChevronIcon />}
    </Styled.Button>
  )
);
