import { useIntl } from 'react-intl';

import { Button, Text, VariablesInterface, parseMarkdown } from '@humanoids/ui';
import { ReactComponent as FireworksDoodleSvg } from '../../images/fireworks-doodle.svg';

interface InquiryDialogCompleteProps {
  onClose: () => void;
  variables: VariablesInterface;
}

export const InquiryDialogComplete = ({
  onClose,
  variables,
}: InquiryDialogCompleteProps) => {
  const intl = useIntl();

  return (
    <>
      <Text
        $align="center"
        dangerouslySetInnerHTML={{
          __html: parseMarkdown(
            intl.formatMessage({
              id: 'inquiryCompleteIntro',
            })
          ),
        }}
        $large
      />
      <FireworksDoodleSvg />
      <Text
        $align="center"
        dangerouslySetInnerHTML={{
          __html: parseMarkdown(
            intl.formatMessage({
              id: 'inquiryCompleteConfirmation',
            }),
            variables
          ),
        }}
        $large
      />
      <Button onClick={onClose} tertiary>
        {intl.formatMessage({
          id: 'inquiryCompleteClose',
        })}
      </Button>
    </>
  );
};
