import Image from 'next/image';
import { Fragment, useContext } from 'react';
import { useIntl } from 'react-intl';

import { Text, parseMarkdown } from '@humanoids/ui';

import { ReactComponent as Gazellen2021Badge } from '@humanoids/images/fd-gazellen-2021-badge.svg';
import { ReactComponent as Gazellen2022Badge } from '@humanoids/images/fd-gazellen-2022-badge.svg';
import { ReactComponent as Gazellen2023Badge } from '@humanoids/images/fd-gazellen-2023-badge.svg';
import { LocationType } from '@humanoids/services/graphql/sdk';
import { StoreContext } from '../../../../../apps/humanoids.nl/components/store-provider';

import * as Styled from './Footer.styled';

interface FooterProps {
  locations: LocationType[];
}

export const Footer = ({ locations }: FooterProps) => {
  const intl = useIntl();

  const [{ configuration }] = useContext(StoreContext);

  const socials = configuration?.at(0)?.socialPlatform;

  return (
    <Styled.Footer>
      <Styled.Container>
        <Styled.HumanoidsLogo />
        <Styled.Accreditations>
          <Text
            dangerouslySetInnerHTML={{
              __html: parseMarkdown(intl.formatMessage({ id: 'fdGazellen' })),
            }}
          />
          <Styled.Awards>
            <Gazellen2021Badge />
            <Gazellen2022Badge />
            <Gazellen2023Badge />
          </Styled.Awards>
        </Styled.Accreditations>
        {locations.map(({ _id, address, photo, title }) => (
          <Styled.Location key={_id}>
            <Styled.Heading
              dangerouslySetInnerHTML={{
                __html: parseMarkdown(title),
              }}
            />
            <Styled.Address>
              <Text
                dangerouslySetInnerHTML={{
                  __html: parseMarkdown(address[intl.locale]),
                }}
              />
            </Styled.Address>
            <Styled.Photo>
              <Image
                alt={photo.alt}
                blurDataURL={photo.asset.metadata.lqip}
                fill
                placeholder="blur"
                quality={85}
                sizes={Styled.photoMediaCondition}
                src={photo.asset.url}
              />
            </Styled.Photo>
          </Styled.Location>
        ))}
        <Styled.Contact>
          <Styled.Heading
            dangerouslySetInnerHTML={{
              __html: parseMarkdown(
                intl.formatMessage({
                  id: 'footerPhoneEmail',
                })
              ),
            }}
          />
          <Text>
            {locations.map(({ _id, phoneNumber }) => (
              <Fragment key={_id}>
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                <br />
              </Fragment>
            ))}
            {locations
              .map(({ emailAddress }) => emailAddress)
              .filter(
                (emailAddress, index, array) =>
                  array.indexOf(emailAddress) === index
              )
              .map((emailAddress) => (
                <Fragment key={emailAddress}>
                  <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                  <br />
                </Fragment>
              ))}
          </Text>
        </Styled.Contact>

        <Styled.Socials>
          <Styled.Heading
            dangerouslySetInnerHTML={{
              __html: parseMarkdown(
                intl.formatMessage({
                  id: 'footerSocials',
                })
              ),
            }}
          />
          <Text>
            {socials.map((social) => (
              <Fragment key={social.url}>
                <a
                  href={social.url}
                  rel="nofollow noreferrer noopener"
                  target="_blank"
                >
                  <Image
                    alt={`${social.platform} logo`}
                    height="22"
                    src={social.logo.asset.url}
                    width="23"
                  />
                  {social.platform}
                </a>
                <br />
              </Fragment>
            ))}
          </Text>
        </Styled.Socials>
      </Styled.Container>
    </Styled.Footer>
  );
};
