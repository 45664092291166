import styled, { css } from 'styled-components';

import * as Types from './Text.types';

export const Text = styled.p<Omit<Types.TextProps, 'children'>>(
  ({ $align, $extraExtraLarge, $extraLarge, $large, $small, theme }) => css`
    color: inherit;
    text-align: ${$align ?? 'left'};
    white-space: pre-line;
    word-break: break-word;

    ${$small &&
    css`
      font-size: 14px;
      line-height: 22px;
    `}

    ${!$small &&
    !$large &&
    !$extraLarge &&
    !$extraExtraLarge &&
    css`
      font-size: 18px;
      line-height: 30px;
    `}
    
    ${$large &&
    css`
      --fontSize: 20px;
      --lineHeight: 32px;
      font-size: var(--fontSize);
      line-height: var(--lineHeight);
    `}

    ${$extraLarge &&
    css`
      font-size: 24px;
      line-height: 38px;
    `}

   ${$extraExtraLarge &&
    css`
      font-size: 24px;
      line-height: 38px;
    `}

    @media (min-width: ${theme.breakpoints.medium}) {
      ${$small &&
      css`
        font-size: 16px;
        line-height: 26px;
      `}

      ${!$small &&
      !$large &&
      !$extraLarge &&
      css`
        font-size: 18px;
        line-height: 28px;
      `}
          
      ${$large &&
      css`
        --fontSize: 24px;
        --lineHeight: 38px;
        font-size: var(--fontSize);
        line-height: var(--lineHeight);
      `}

      ${$extraLarge &&
      css`
        font-size: 32px;
        line-height: 48px;
      `}

      ${$extraExtraLarge &&
      css`
        font-size: 40px;
        line-height: 56px;
      `}
    }

    mark:nth-of-type(odd) {
      background-color: ${theme.brandColors.primary.main};
    }

    mark:nth-of-type(even) {
      background-color: ${theme.brandColors.secondary.main};
    }

    a {
      color: ${theme.brandColors.secondary.main};
    }
  `
);
