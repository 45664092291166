import styled, { css } from 'styled-components';
import LinkBase from 'next/link';

import { Container as ContainerBase } from '@humanoids/ui';

import { ReactComponent as LogoSvg } from '@humanoids/images/humanoids-logo.svg';

export const Navigation = styled.nav(
  ({ theme }) => css`
    align-items: center;
    background-color: ${theme.backgroundColor.contrast};
    height: 68px;
    left: 0;
    right: 0;
    padding: 0;
    position: sticky;
    top: 0;
    z-index: 100;

    @media (min-width: ${theme.breakpoints.medium}) {
      height: 95px;
    }
  `
);

export const Backdrop = styled.div`
  background-color: #ffffff;
  position: relative;
`;

export const Container = styled(ContainerBase)(
  ({ theme }) => css`
    align-items: center;
    display: grid;
    grid-template-columns: 20% 1fr 20%;
    padding: 22px 0; // The padding is 24px measured from the logo
    position: relative;

    @media (min-width: ${theme.breakpoints.medium}) {
      grid-template-columns: 1fr auto 1fr;
      padding: 36px var(--spacing);
    }
  `
);

export const Link = styled(LinkBase)(
  ({ theme }) => css`
    align-content: center;
    display: grid;
    justify-content: center;

    @media (min-width: ${theme.breakpoints.medium}) {
      margin: 0;
    }
  `
);

export const Logo = styled(LogoSvg)`
  height: 20px;
  width: 100%;
`;
