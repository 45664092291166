const host = 'https://api.hubapi.com';
const headers = {
  Authorization: `Bearer ${process.env.HUBSPOT_TOKEN}`,
  'Content-Type': 'application/json',
};

type ReturnValue = {
  id: number;
};

export type ContactType = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

const errorHandler = (error) => {
  console.log(error);
  return error;
};

export const createContact = async (
  contact: ContactType
): Promise<ReturnValue | null> => {
  if (process.env.NODE_ENV !== 'development') {
    if (
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_HOST_NAME === 'https://humanoids.nl'
    ) {
      return fetch('/api/hubspot/contacts', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contact),
        method: 'POST',
      })
        .then((response) => response.json())
        .catch(errorHandler);
    }

    return fetch(`${host}/crm/v3/objects/contacts`, {
      headers,
      body: JSON.stringify({
        properties: {
          email: contact.emailAddress,
          firstname: contact.firstName,
          lastname: contact.lastName,
          phone: contact.phoneNumber,
        },
      }),
      method: 'POST',
    })
      .then((response) => response.json())
      .then(({ id, message, status }) => {
        if (status && status === 'error') {
          return {
            id: Number(message.replace(/\D/g, '')),
          };
        }

        return { id };
      })
      .catch(errorHandler);
  }
  return null;
};

export const createDeal = async (
  contactId: number
): Promise<ReturnValue | null> => {
  if (process.env.NODE_ENV !== 'development') {
    if (
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_HOST_NAME === 'https://humanoids.nl'
    ) {
      return fetch('/api/hubspot/deals', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contactId: Number(contactId),
        }),
        method: 'POST',
      })
        .then((response) => response.json())
        .catch(errorHandler);
    }

    return fetch(`${host}/crm/v3/objects/deals`, {
      headers,
      body: JSON.stringify({
        properties: {
          dealname: 'Website lead',
          pipeline: process.env.HUBSPOT_PIPELINE,
          dealstage: process.env.HUBSPOT_DEALSTAGE,
        },
        associations: [
          {
            to: {
              id: contactId,
            },
            types: [
              {
                associationCategory: 'HUBSPOT_DEFINED',
                associationTypeId: 3,
              },
            ],
          },
        ],
      }),
      method: 'POST',
    })
      .then((response) => response.json())
      .then(({ id }) => ({ id }))
      .catch(errorHandler);
  }
  return null;
};

export const addNoteToDeal = async (
  dealId: number,
  note: string
): Promise<ReturnValue | null> => {
  if (process.env.NODE_ENV !== 'development') {
    if (
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_HOST_NAME === 'https://humanoids.nl'
    ) {
      return fetch(`/api/hubspot/deals/${dealId}/notes`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ note }),
        method: 'POST',
      })
        .then((response) => response.json())
        .catch(errorHandler);
    }

    return fetch(`${host}/crm/v3/objects/notes`, {
      headers,
      body: JSON.stringify({
        properties: {
          hs_note_body: note,
          hs_timestamp: new Date().toISOString(),
        },
        associations: [
          {
            to: {
              id: dealId,
            },
            types: [
              {
                associationCategory: 'HUBSPOT_DEFINED',
                associationTypeId: 214,
              },
            ],
          },
        ],
      }),
      method: 'POST',
    })
      .then((response) => response.json())
      .then(({ id }) => ({ id }))
      .catch(errorHandler);
  }
  return null;
};
