import { IntlProvider } from 'react-intl';
import { StyleSheetManager } from 'styled-components';
import NextApp, { AppContext, AppProps as AppPropsBase } from 'next/app';
import { useRouter } from 'next/compat/router';
import localFont from 'next/font/local';
import Head from 'next/head';
import { GoogleTagManager } from '@next/third-parties/google';
import isPropValid from '@emotion/is-prop-valid';

import { GlobalStyle, HumThemeProvider } from '@humanoids/ui';

import { InquiryCta, InquiryDialog, Footer } from '@humanoids/ui';
import { Navigation, NavigationProps } from '@/components/navigation';
import { StickyNotification } from '@/components/sticky-notification';
import { StoreProvider } from '@/components/store-provider';
import {
  ConfigurationType,
  InquiryType,
  LocationType,
  NavigationItemType,
  PageType,
  StickyNotificationType,
  TranslationMetadataType,
  TranslationType,
} from '@humanoids/services/graphql/sdk';
import { graphQlRequestSdk } from '../services';

interface AppProps extends AppPropsBase {
  articles: PageType[];
  cases: PageType[];
  configuration: ConfigurationType[];
  inquiry: InquiryType;
  locations: LocationType[];
  navigationItems: NavigationItemType[];
  stickyNotification: StickyNotificationType;
  translationMetadata: TranslationMetadataType[];
  translations: TranslationType[];
}

const maisonNeueFont = localFont({
  src: [
    {
      path: '../public/fonts/maison-neue/maison-neue-light.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/maison-neue/maison-neue-book.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../public/fonts/maison-neue/maison-neue-demi.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../public/fonts/maison-neue/maison-neue-bold.woff2',
      weight: '800',
      style: 'normal',
    },
  ],
});

function App({
  Component,
  articles,
  cases,
  configuration,
  inquiry,
  locations,
  navigationItems,
  pageProps,
  stickyNotification,
  translationMetadata,
  translations,
}: AppProps & NavigationProps) {
  const router = useRouter();

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta
          name="theme-color"
          content="#ffffff"
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content="#2f2f2f"
          media="(prefers-color-scheme: dark)"
        />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      {process.env.NODE_ENV !== 'development' && (
        <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_ID} />
      )}
      <StyleSheetManager
        // TODO: shouldForwardProp is not typed in @types/styled-components yet
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        shouldForwardProp={(propName, elementToBeRendered) =>
          typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
        }
      >
        <IntlProvider
          defaultLocale={router.defaultLocale}
          locale={router.locale}
          messages={translations[router.locale]}
        >
          <div className={maisonNeueFont.className}>
            <HumThemeProvider>
              <StoreProvider
                articles={articles}
                cases={cases}
                configuration={configuration}
                translationMetadata={translationMetadata}
              >
                <GlobalStyle />
                <StickyNotification {...stickyNotification} />
                <Navigation navigationItems={navigationItems} />
                <Component {...pageProps} />
                <Footer locations={locations} />
                <InquiryDialog inquiry={inquiry} />
                <InquiryCta />
              </StoreProvider>
            </HumThemeProvider>
          </div>
        </IntlProvider>
      </StyleSheetManager>
    </>
  );
}

App.getInitialProps = async (appContext: AppContext) => {
  const appProps = await NextApp.getInitialProps(appContext);

  const data = await graphQlRequestSdk.HumanoidsNl({
    locale: appContext.router.locale ?? 'nl',
  });

  return {
    ...appProps,
    articles: data.articles,
    cases: data.cases,
    configuration: data.configuration,
    inquiry: data.inquiry.at(0),
    locations: data.locations,
    navigationItems: data.navigation.at(0).navigationItems,
    stickyNotification: data.stickyNotification.at(0),
    translationMetadata: data.translationMetadata.filter(({ schemaTypes }) =>
      schemaTypes.includes('page')
    ),
    translations: appContext.router.locales.reduce(
      (locales, locale) => ({
        ...locales,
        [locale]: data.translations.reduce(
          (keys, translation) => ({
            ...keys,
            [translation.key]: translation.translations?.[locale],
          }),
          {}
        ),
      }),
      {}
    ),
  };
};

export default App;
